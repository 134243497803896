import styled, { css } from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

const ContactBackground = '#95907f';

const fadeInAnimation = (delay: number, speed: number) => css`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  opacity: 0;
  animation: ${speed}s linear ${delay}s fadeIn;
  animation-fill-mode: forwards;
`;

export const Headline = styled.h2`
  position: absolute;
  font-family: 'Crimson Text', serif;
  font-style: italic;
  font-weight: bolder;
  font-size: 12vw;
  text-align: right;
  top: 190px;
  right: 10vw;
  margin: 0px;
  line-height: 1em;
  filter: drop-shadow(2px 4px 5px black);

  ${fadeInAnimation(0.5, 2)}

  @media ${BREAKPOINT.MEDIUM} {
    font-size: 9vw;
    text-align: left;
    top: auto;
    bottom: 0;
    right: auto;
    left: 10vw;
    margin: 0px;
    line-height: 1.3em;
  }
`;

export const Container = styled.div`
  position: relative;
  display: inline;
  height: 100dvh;
  color: white;
  overflow: hidden;

  @media ${BREAKPOINT.MEDIUM} {
    display: inline-flex;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: block;
  background: black;
  overflow: hidden;
  height: 35vh;
  width: 100vw;
  color: white;

  /* h2 {
    position: absolute;
    top: 45vh;
    left: 25%;
  } */

  video {
    ${fadeInAnimation(1, 2)}

    margin-left: -85px;
    width: 150%;
  }

  @media ${BREAKPOINT.MEDIUM} {
    height: 100dvh;
    width: 50vw;
    animation: 1s scaleIn ease-in-out forwards;

    video {
      height: 100%;
      margin-left: -300px;
      width: auto;
    }
  }

  @keyframes scaleIn {
    from {
      width: 100vw;
    }
    to {
      width: 50vw;
    }
  }
`;

export const TextContainer = styled.div`
  display: block;
  background: ${ContactBackground};
  scale: 3;
  color: black;
  height: 100dvh;
  width: 100vw;
  padding: 55px 0 10px;
  font-family: 'serif';
  animation: 1s bgScale ease-in-out 0s forwards;
  input,
  textarea {
    pointer-events: none;
  }

  & > div {
    ${fadeInAnimation(1, 1)}
  }

  @media ${BREAKPOINT.MEDIUM} {
    height: 100dvh;
    width: 50vw;
    padding-top: 140px;
    input,
    textarea {
      pointer-events: all;
    }
  }

  @keyframes bgScale {
    from {
      scale: 3;
    }
    to {
      scale: 1;
    }
  }
`;

export const FormWrapper = styled.div`
  width: 85%;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  padding-top: 120px;
  background: ${ContactBackground};
  width: 100vw;
`;

export const SuccessImg = styled.img`
  display: block;
  margin: 0 auto;
  margin-top: 20%;
  width: 50%;
  max-width: 200px;
`;
