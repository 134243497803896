import styled, { css } from 'styled-components';

interface IOverlay {
  enabled: boolean;
}

const ShowOverlay = css`
  transform: scale(10, 10);
  width: 1000px;
  height: 1000px;
`;

const DisableOverlay = css`
  transform: scale(0, 0);
  width: 30px;
  height: 30px;
`;

export const Overlay = styled.div<IOverlay>`
  ${({ enabled }) => (enabled ? ShowOverlay : DisableOverlay)}
  position: absolute;
  transition: all 1s;
  background-color: orange;
  border-radius: 50%;
  z-index: 2;
  top: 20px;
  right: 30px;

  animation: colorFade 1s;

  @keyframes colorFade {
    0% {
      background-color: white;
    }
    100% {
      background-color: orange;
    }
  }
`;

export const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
`;

export const Content = styled.div<IOverlay>`
  position: absolute;
  top: 60px;
  left: 0;
  text-align: center;
  transition: all 0.5s;
  opacity: ${({ enabled }) => (enabled ? '1' : '0')};
  z-index: 9;
  height: calc(100dvh - 60px);
  width: 100vw;
  cursor: default;
`;
