import { Vortex } from 'react-loader-spinner';

import * as SC from './styled';

const LoadingAnimation = () => {
  return (
    <SC.LoadingAnimation>
      <Vortex
        visible={true}
        height="150"
        width="150"
        ariaLabel="vortex-loading"
        wrapperStyle={{}}
        colors={[
          '#e6e6e6',
          '#eaeaea',
          '#ececec',
          '#dddddd',
          '#e2e2e2',
          '#dddddd',
        ]}
      />
    </SC.LoadingAnimation>
  );
};

export default LoadingAnimation;
