import { useState, useMemo } from 'react';
import { FadeOverlay, WipeOverlay } from './styled';

type TDirection = 'up' | 'down';
export type TAnimation = 'wipe' | 'fade';

const ScrollLayer = () => {
  const [isActive, setIsActive] = useState(false);
  const [isDirection, setDirection] = useState<TDirection>('down');
  const [animationType, setAnimationType] = useState<TAnimation>('wipe');

  const fireLayer = (direction: TDirection, animation: TAnimation) => {
    setDirection(direction);
    setAnimationType(animation);
    setIsActive(true);

    setTimeout(() => {
      setIsActive(false);
    }, 1500);
  };

  const Layer = useMemo(() => {
    return (
      <LayerComponent
        active={isActive}
        direction={isDirection}
        animationType={animationType}
      />
    );
  }, [isActive, isDirection, animationType]);

  return [{ fireLayer, Layer }];
};

const LayerComponent = ({
  active,
  direction,
  animationType,
}: {
  active: boolean;
  direction: TDirection;
  animationType: TAnimation;
}) => (
  <>
    {animationType === 'wipe' ? (
      <WipeOverlay active={active} direction={direction} />
    ) : (
      <FadeOverlay active={active} direction={direction} />
    )}
  </>
);

export default ScrollLayer;
