import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

export const Container = styled.div`
  position: absolute;
  height: 40px;
  width: 100%;
  bottom: 10px;

  @media ${BREAKPOINT.MEDIUM} {
    bottom: 80px;
  }
`;

export const IconWrapper = styled.div`
  display: block;
  width: 50px;
  margin: auto;
  color: white;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    color: black;
  }
`;
