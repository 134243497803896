import { createContext, useEffect, useState } from 'react';
import { usePrevious } from 'utils/hooks';

interface INavProvider {
  children: JSX.Element[] | JSX.Element;
}

export type TDirection = 'up' | 'down' | undefined;

type NavContextProps = {
  state: {
    index: number;
    amount: number;
    direction: TDirection;
  };
  actions: {
    setPageIndex: React.Dispatch<React.SetStateAction<number>>;
    setPageAmount: React.Dispatch<React.SetStateAction<number>>;
  };
};

export const NavContext = createContext<NavContextProps>({
  state: { index: 0, direction: undefined, amount: 0 },
  actions: {
    setPageIndex: (index) => index,
    setPageAmount: (amount) => amount,
  },
});

export const NavProvider = ({ children }: INavProvider) => {
  const [index, setIndex] = useState(0);
  const [amount, setAmount] = useState(0);

  const [direction, setDirection] = useState<TDirection>();

  const prevIndex = usePrevious(index) || 0;

  useEffect(() => {
    if (prevIndex !== index) setDirection(prevIndex > index ? 'up' : 'down');
    // console.log({ index });
  }, [index, prevIndex]);

  return (
    <NavContext.Provider
      value={{
        state: { index, amount, direction },
        actions: { setPageIndex: setIndex, setPageAmount: setAmount },
      }}>
      {children}
    </NavContext.Provider>
  );
};
