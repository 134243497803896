import {
  BsChevronCompactDown,
  BsArrowLeft,
  BsArrowRight,
} from 'react-icons/bs';

import * as SC from './styled';
import { useEffect, useState } from 'react';
import Carousel, { CarouselProps } from 'nuka-carousel';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const headerImgsPath = process.env.PUBLIC_URL + '/imgs/header/';

interface ISlideShow {
  photos?: Photo[];
  inView?: boolean;
}

type Photo = {
  src: string;
  title?: string;
  ratio: number;
};

const RightNav = ({ onClick }: any) => {
  return (
    <SC.RightNav onClick={onClick}>
      <BsArrowRight size={25} />
    </SC.RightNav>
  );
};

const LeftNav = ({ onClick }: any) => {
  return (
    <SC.LeftNav onClick={onClick}>
      <BsArrowLeft size={25} />
    </SC.LeftNav>
  );
};

const Indicator = ({ index, count }: any) => (
  <SC.Indicator>
    {index + 1} — {count}
  </SC.Indicator>
);

const SlideShow = ({ photos, inView }: ISlideShow) => {
  const { trackPageView } = useMatomo();
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const photosCount = (photos && Object.keys(photos).length) || 0;

  const settings: CarouselProps = {
    autoplay: true,
    autoplayInterval: 8000,
    disableAnimation: false,
    dragThreshold: 0.2,
    swiping: true,
    wrapAround: true,
    afterSlide: (index) => setSlideIndex(index),
    defaultControlsConfig: {
      pagingDotsStyle: { display: 'none' },
      nextButtonStyle: { background: 'transparent' },
      prevButtonStyle: { background: 'transparent' },
      nextButtonText: <RightNav />,
      prevButtonText: <LeftNav />,
    },
  };

  useEffect(() => {
    inView &&
      trackPageView({
        documentTitle: '#SlideShow',
      });
  }, [inView, trackPageView]);

  return (
    <SC.Wrapper>
      <Carousel {...{ ...settings, autoplay: inView }}>
        {photos?.map(
          (photo, index) =>
            index <= slideIndex + 1 && (
              <SC.SlideImage key={index}>
                <SC.Image image={headerImgsPath + photo.src} />
              </SC.SlideImage>
            )
        )}
      </Carousel>

      <Indicator index={slideIndex} count={photosCount} />
    </SC.Wrapper>
  );
};

export default SlideShow;
