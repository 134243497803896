import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 65px;
  top: 10px;
  left: 0px;
  right: 0px;
  margin: auto;
  padding-top: 20px;
  height: 100px;
  opacity: 0.9;
`;

interface IDot {
  marked?: boolean;
}
export const Dot = styled.div<IDot>`
  display: inline-block;
  width: ${({ marked }) => (marked ? '10px' : '5px')};
  height: ${({ marked }) => (marked ? '10px' : '5px')};
  border-radius: 15px;
  background: white;
  transition: all 0.4s;
  margin: 0 5px;
  margin-bottom: ${({ marked }) => (marked ? '-2px' : '0px')};
`;
