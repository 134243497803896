import { useEffect, useRef, useState } from 'react';
import {
  Container,
  Image,
  Title,
  Text,
  ExampleBox,
  ExampleBoxContainer,
  ExampleBoxStrip,
  ExampleBoxShadow,
} from './styled';

export type Images = {
  src: string;
  ratio: number;
  upright?: boolean;
};

export type TExampleElement = {
  images?: Images[];
  headline: string;
  text: string;
  visible?: boolean;
  accent?: string;
};

const ExampleElement = ({
  headline,
  images,
  text,
  visible,
  accent = 'black',
}: TExampleElement) => {
  const imgPath = '/imgs/header/';
  const imagesCount = images?.length || 0;
  const interval = useRef<NodeJS.Timeout>();

  const [imgIndex, setImgIndex] = useState(0);

  const goForward = () => {
    setImgIndex((value) => {
      if (value < imagesCount - 1) {
        return value + 1;
      }
      clearInterval(interval.current);
      return value;
    });
  };

  const pickByHand = (index: number) => {
    setImgIndex(index);
    clearInterval(interval.current);
  };

  useEffect(() => {
    if (visible) {
      setImgIndex(0);
      interval.current = setInterval(goForward, 3000);

      return () => {
        clearInterval(interval.current);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return visible && images ? (
    <Container>
      {
        // Für sehr keine ios screens
        screen.availHeight > 670 ? (
          <Title>{headline}</Title>
        ) : (
          <div style={{ marginTop: 10 }} />
        )
      }

      <Image
        image={imgPath + images[imgIndex].src}
        ratio={images[imgIndex].ratio}
        upright={images[imgIndex].ratio > 1}
      />
      <ExampleBoxContainer>
        {/* <ExampleBoxShadow accent={accent} /> */}
        <ExampleBoxStrip index={imgIndex} count={images.length}>
          {images.map((image, index) => (
            <ExampleBox
              key={index}
              image={imgPath + image.src}
              selected={imgIndex === index}
              onClick={() => pickByHand(index)}
            />
          ))}
        </ExampleBoxStrip>
      </ExampleBoxContainer>
      <Text>{text}</Text>
    </Container>
  ) : null;
};

export default ExampleElement;
