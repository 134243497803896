import styled, { css } from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

interface IInputField {
  hasError: boolean;
}
const InputStyle = ({ hasError }: IInputField) => css`
  appearance: none;
  border: none;
  border-radius: 25px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-color: ${hasError ? '#9d0d0d' : 'white'};
  background-color: ${hasError ? '#9d6363' : '#938f81'};

  font-size: 1.3em;
  margin-bottom: 30px;
  color: white;
  padding: 18px 0 10px;
  padding-left: 10px;
  opacity: 0.6;
  transition: opacity 0.1s linear;
  transition: all 0.4s;
  width: calc(100% - 10px);
  box-shadow: 7px 7px 0px black;

  &::placeholder {
    color: white;
    font-size: 1em;
    opacity: 0.5;
    font-family: 'Courgette';
  }

  &:focus {
    outline: none;
    opacity: 1;

    &::placeholder {
      opacity: 0.1;
    }
  }

  @media ${BREAKPOINT.MEDIUM} {
    font-size: 2em;
    margin-bottom: 60px;
    padding: 20px 0px 10px 10px;
  }
`;

export const FormContainer = styled.div`
  position: relative;
  /* height: 100dvh; */
`;

interface IFormContainer {
  enabled: boolean;
}
export const Form = styled.form<IFormContainer>`
  display: ${({ enabled }) => (enabled ? 'block' : 'none')};
  height: 100dvh;
`;

export const InputField = styled.input<IInputField>`
  ${InputStyle}
`;

export const InputArea = styled.textarea<IInputField>`
  resize: none;
  ${InputStyle}
`;

export const SubmitButton = styled.button`
  position: relative;
  background: none;
  color: white;
  font-size: 1.5em;
  font-family: 'Courgette';
  border: none;
  border-radius: 45px;
  border-top: 2px solid white;
  border-bottom: 2px solid white;
  padding: 8px 15px;
  float: right;
  transition: padding 0.4s;
  box-shadow: 7px 7px 0px #3c3a33;
  cursor: pointer;

  span {
    transition: padding 0.4s;
  }

  span:after {
    content: '\\00bb';
    position: absolute;
    opacity: 0;
    right: -15px;
    transition: 0.5s;
    padding-right: 15px;
  }

  &:hover {
    background-color: #585858;

    span {
      padding-right: 25px;
    }
  }

  &:hover span:after {
    opacity: 1;
    right: 0;
  }

  &:active {
    outline: none;
    background-color: #585858;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  @media ${BREAKPOINT.MEDIUM} {
    padding: 8px 25px;
    font-size: 2em;
  }
`;
