export const DefaultHintMessage = () => (
  <p>
    Gerne stehe ich dir zur Verfügung, falls du Fragen zu den Aufnahmen hast
    oder eine Veredelung für einen hochwertigen Druck wünschst. Zögere nicht,
    mich zu kontaktieren.
    <br />
    <b>Ich freue mich auf deine Nachricht und helfe dir gerne weiter!</b>
  </p>
);

export const WeddingHintMessage = () => (
  <p>
    Willkommen in der Hochzeitsgalerie!💐 <br />
    Tauche ein in die wunderschönen Momente dieses unvergesslichen Tages. <br />
    <br />
    <b>Deine Meinung ist für uns von unschätzbarem Wert</b> und hilft uns unsere
    Arbeit weiter zu verbessern. Jegliches Lob wird ausserdem in zukünftigen
    Instagram Stories geteilt😍 Klicke einfach unten auf den Button, um uns
    deine Gedanken zukommen zu lassen. Wir freuen uns darauf, von dir zu hören
    und bedanken uns herzlich für deine Unterstützung!😊
  </p>
);
