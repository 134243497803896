import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

export const Container = styled.div`
  border: 4px solid white;
  padding-bottom: 80px;
  background: white;

  @media ${BREAKPOINT.MEDIUM} {
    border: 10px solid white;
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: 100px;
`;

export const ScrollTopButton = styled.div`
  color: white;
  width: 140px;
  margin: auto;
  padding: 15px;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  font-family: 'Courgette';
  font-size: 1.2rem;
  background: #4a4a4a;
`;
