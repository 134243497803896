import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

export const Container = styled.div`
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
`;

type HeaderType = {
  titleImage: string;
};

export const Header = styled.div<HeaderType>`
  position: relative;
  height: 100dvh;
  padding: 10px;

  :after {
    content: '';
    position: fixed;
    top: 0;
    height: 100dvh;
    left: 0;
    right: 0;
    z-index: -1;
    background: black;
    background: ${({ titleImage }) => `url(${titleImage})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(5px) grayscale(1);
    animation: 4s ease-in-out 0s fadeInGallery forwards;
    opacity: 0;
  }

  @keyframes fadeInGallery {
    0% {
      filter: blur(5px) grayscale(1);
    }
    50% {
      filter: blur(3px) grayscale(1);
      opacity: 0.8;
    }
    100% {
      filter: blur(0px) grayscale(0);
      opacity: 1;
    }
  }
`;

export const HeaderContent = styled.div`
  position: absolute;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-family: 'Lora', serif;
  opacity: 0;
  animation: 2s linear 2.5s fadeInGallery forwards;
`;

export const SignatureWrapper = styled.div`
  width: 160px;
  top: -42px;
  left: -26px;
  position: relative;
  float: left;

  svg {
    fill: black;
  }
`;

export const Title = styled.div`
  h1 {
    font-size: 2.5rem;
    margin: 0;

    @media ${BREAKPOINT.MEDIUM} {
      font-size: 5rem;
    }
  }

  h3 {
    line-height: 0;
    font-size: 1rem;
    font-weight: 100;

    @media ${BREAKPOINT.MEDIUM} {
      font-size: 2rem;
    }
  }
`;

export const ShowButton = styled.button`
  display: inline-block;
  border: 1px solid white;
  font-size: 1rem;
  text-align: center;
  margin: 45px auto 0px;
  padding: 15px;
  background: transparent;
  color: white;
  cursor: pointer;
  transition: all 0.3s;
  font-family: 'Lora', serif;

  &:hover {
    background: #d3d3d340;
    scale: 1.05;
  }

  @media ${BREAKPOINT.MEDIUM} {
    font-size: 1.5rem;
    margin: 75px auto 0px;
  }
`;

export const OptionsArea = styled.div`
  padding: 10px 15px 5px;
  display: inline-flex;
  background: white;
  height: 50px;
  width: calc(100% - 30px);
`;

export const Tools = styled.div`
  margin-left: auto;
  display: inline-flex;
`;

export const TitleField = styled.div`
  margin-right: auto;
`;
