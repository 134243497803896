import styled from 'styled-components';

export const Container = styled.div`
  &:hover {
    border: 0px solid green;
  }
`;

export const NameTag = styled.div`
  position: absolute;
  overflow: hidden;
  inset: auto 0 0 0;
  padding: 15px 5px 5px;
  color: white;
  background: linear-gradient(
    to bottom,
    transparent 0,
    rgba(0, 0, 0, 0.7) 100%
  );
  transition: background 150ms ease-in-out, opacity 150ms ease-in-out;
`;
