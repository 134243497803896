import styled, { css } from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

interface ISlideAnimation {
  delay: number;
  fromLeft: boolean;
}
const SlideInAnimation = ({ delay, fromLeft }: ISlideAnimation) => {
  const executionTime = Number(delay) + 1.5;
  const transformStart = fromLeft ? '300px' : '-1000px';

  return css`
    opacity: 0;
    animation: slideIn ${executionTime}s ease forwards;

    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(${transformStart});
      }
      to {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  `;
};

const ImgUpright = css`
  max-height: 50vh;
  aspect-ratio: 2 / 3;

  @media ${BREAKPOINT.MEDIUM} {
    width: auto;
    max-height: 60vh;
  }
`;

const ImgAcross = css`
  max-width: 85vw;
  max-height: 50vh;
  aspect-ratio: 3 / 2;

  @media ${BREAKPOINT.MEDIUM} {
    width: auto;
    max-height: 60vh;
  }
`;

export const Container = styled.div`
  position: relative;
  background: transparent;
  top: 4vh;

  @media ${BREAKPOINT.MEDIUM} {
    padding-left: 60px;
  }
`;

export const Title = styled.h2`
  position: relative;
  display: inline-block;
  text-align: right;
  margin-top: 0px;
  width: calc(100vw - 70px);
  left: 0px;

  @media ${BREAKPOINT.MEDIUM} {
    text-align: left;
    left: -55px;
  }

  ${SlideInAnimation({ delay: 0.1, fromLeft: true })}
`;

interface IImage {
  image: string;
  ratio?: number;
  upright?: boolean;
}
export const Image = styled.div<IImage>`
  position: relative;
  display: block;
  margin-left: -55px;
  border: 8px white solid;
  background: url(${({ image }) => image && image});
  background-color: #ededed;
  background-size: cover;
  transition: all 0.5s;

  ${({ upright }) => (upright ? ImgUpright : ImgAcross)}

  @media ${BREAKPOINT.MEDIUM} {
    border: 12px white solid;
  }

  ${SlideInAnimation({ delay: 0.6, fromLeft: false })}
`;

export const Text = styled.p`
  position: relative;
  display: inline-block;
  padding-top: 20px;
  width: 80%;

  ${SlideInAnimation({ delay: 1, fromLeft: false })};
`;

export const ExampleBoxContainer = styled.div`
  position: relative;
  display: block;
  padding-top: 20px;
  float: right;
  margin-right: 10px;
  cursor: pointer;
  width: 80vw;
  height: 20vw;
  overflow: hidden;

  @media ${BREAKPOINT.MEDIUM} {
    height: 105px;
  }
`;

interface IExampleBoxStrip {
  index: number;
  count: number;
}
export const ExampleBoxStrip = styled.div<IExampleBoxStrip>`
  position: absolute;
  left: calc(
    (
        -10px + (12vw *
              ${({ index, count }) => (count > 5 && index > 2 ? index : 0)})
      ) * -1
  );
  transition: 1s;

  -webkit-tap-highlight-color: transparent;
  white-space: nowrap;

  @media ${BREAKPOINT.MEDIUM} {
    left: calc(
      (
          -20px + (50px *
                ${({ index, count }) => (count > 5 && index > 3 ? index : 0)})
        ) * -1
    );
  }

  ${SlideInAnimation({ delay: 2, fromLeft: true })};
`;

interface IExampleBoxShadow {
  accent: string;
}
export const ExampleBoxShadow = styled.div<IExampleBoxShadow>`
  position: absolute;
  top: -30px;
  right: 0;
  width: 80vw;
  height: 200px;
  box-shadow: ${({ accent }) => accent} 0px 0px 11px 16px inset;

  @media ${BREAKPOINT.MEDIUM} {
    max-width: 520px;
  }
`;

interface IExampleBox {
  image: string;
  selected: boolean;
  upright?: boolean;
}
export const ExampleBox = styled.div<IExampleBox>`
  display: inline-block;
  width: 15vw;
  height: 15vw;
  background: url(${({ image }) => image && image});
  background-color: #ededed;
  background-size: cover;
  overflow: hidden;
  border: 2px solid white;
  margin-bottom: -5px;
  filter: grayscale(${({ selected }) => (selected ? 0 : 1)});
  transition: filter 1s;

  @media ${BREAKPOINT.MEDIUM} {
    width: 100px;
    height: 100px;
  }
`;
