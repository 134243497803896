import { BsChevronCompactDown } from 'react-icons/bs';
import { ScrollArrow } from './styled';
import { NavContext } from 'context/NavContext';
import { useContext } from 'react';

const Footer = () => {
  const {
    state: { index: currentIndex, amount },
    actions: { setPageIndex },
  } = useContext(NavContext);

  const handleNextCta = () => {
    setPageIndex(currentIndex + 1);
  };

  return (
    (currentIndex + 1 < amount && (
      <ScrollArrow onClick={handleNextCta}>
        <BsChevronCompactDown size={50} />
      </ScrollArrow>
    )) || <></>
  );
};

export default Footer;
