import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

export const NavContainer = styled.div`
  padding-top: 8vh;

  @media ${BREAKPOINT.MEDIUM} {
    padding-top: 15vh;
  }
`;
