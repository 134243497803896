import photosJson from 'assets/imgIndex/headImgIndex.json';
import * as SC from './styled';
import FullPageSlider from 'components/FullPageScroller';
import Page from 'components/Page';
import SlideShow from '../pages/SlideShow';
import Info from '../pages/Info';
import Examples from '../pages/Examples';
import Contact from '../pages/Contact';
import { DisableScrollbar } from 'utils/globalStyles';

const Landing = () => {
  return (
    <SC.Landing>
      <DisableScrollbar />

      <FullPageSlider>
        <Page id="slideshow" animation="wipe">
          <SlideShow photos={photosJson.base} />
        </Page>
        <Page id="info" animation="wipe">
          <Info />
        </Page>
        <Page id="examples" animation="fade">
          <Examples />
        </Page>
        <Page id="contact" animation="wipe">
          <Contact />
        </Page>
      </FullPageSlider>
    </SC.Landing>
  );
};

export default Landing;
