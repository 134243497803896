import { useEffect } from 'react';
import ExampleSlider from 'components/ExampleSlider';
import { TExampleElement } from 'components/ExampleElement';
import { Container } from './styled';
import photosJson from 'assets/imgIndex/headImgIndex.json';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

interface IExamples {
  inView?: boolean;
}
const Examples = ({ inView }: IExamples) => {
  const { trackPageView, trackEvent } = useMatomo();

  const slides: TExampleElement[] = [
    {
      headline: 'Hochzeitsfotografie',
      text: '',
      // text: 'Gerne begleite ich dich an Ihrem schönsten Tag des Lebends und sorge dafür, dass dich sich noch viele Jahre an hochwertigen Bildern erfreuen können.',
      images: photosJson.hochzeit,
      accent: 'pink',
    },
    {
      headline: 'Naturfotografie',
      text: '',
      // text: 'In der Natur unterwegs zu sein mit einer Kamera macht das Abenteuer noch mal zu etwas besonderem da man die Umgebung mehr wahrnimmt um das Perfekte Motiv zu finden. Auf Anfrage biete ich Ihnen gerne verschiedene Motive in hoher qualität als Print an.',
      accent: 'green',
      images: photosJson.natur,
    },
    {
      headline: 'Fotografie',
      text: '',
      // text: 'Fotografieren bedeutet kreativität. Jedes Motiv kann zu einem Kunstwerk werden.',
      images: photosJson.urban,
      accent: '#101010',
    },
  ];

  const trackSlideAction = (index: number) => {
    trackEvent({
      category: 'Examples',
      action: `Slide: ${slides[index].headline}`,
    });
  };

  useEffect(() => {
    inView &&
      trackPageView({
        documentTitle: '#Examples',
        category: 'Examples',
      });
  }, [inView, trackPageView]);

  return (
    <Container>
      <ExampleSlider elements={slides} trackAction={trackSlideAction} />
    </Container>
  );
};

export default Examples;
