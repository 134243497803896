import { useState, useCallback } from 'react';
import { RotatingSquare } from 'react-loader-spinner';
import PhotoAlbum from 'react-photo-album';
import Lightbox, { Slide } from 'yet-another-react-lightbox';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import Video from 'yet-another-react-lightbox/plugins/video';
// import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';

import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/captions.css';
// import 'yet-another-react-lightbox/plugins/thumbnails.css';

import api from 'utils/api';
import { useBreakpoint } from 'utils/hooks';
import { AlbumType } from 'views/customerArea/types';
import Download from './ActionIcons/Download';
import Picture from './Picture';
import { ButtonWrapper, Container, ScrollTopButton } from './styled';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

interface IGallery {
  albumData: AlbumType;
  scrollToStart: () => void;
}

const Gallery = ({ scrollToStart, albumData }: IGallery) => {
  const { isMobile } = useBreakpoint();
  const { trackEvent } = useMatomo();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const { images, folder } = albumData;

  const openLightbox = useCallback(({ index }: { index: number }) => {
    setCurrentImageIndex(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImageIndex(0);
    setViewerIsOpen(false);
  };

  const srcFolder = api.defaults.baseURL + '/galleries/' + folder;
  const videoExtenstions = ['.mp4'];

  const thumbSizeImgs = images.map((image, index) => {
    const extname = image.path.substring(image.path.indexOf('.'));
    const isVideo = videoExtenstions.includes(extname.toLowerCase());

    const imgSrc = !isVideo
      ? srcFolder + '/thumbnails/' + image.path?.replace('.JPG', '.jpg')
      : '/imgs/video_placeholder.png';

    return {
      ...image,
      key: index.toString(),
      type: isVideo ? 'video' : 'image',
      src: imgSrc,
      title: image.name,
    };
  });

  const fullSizeImgs = images.map((image) => {
    const extname = image.path.substring(image.path.indexOf('.'));
    const isVideo = videoExtenstions.includes(extname.toLowerCase());

    return {
      ...image,
      type: isVideo ? 'video' : 'image',
      src: srcFolder + '/' + image.path,
      description: image.name,
      poster: '',
      sources: [
        {
          src: srcFolder + '/' + image.path,
          type: isVideo
            ? `video/${extname.substring(1).toLowerCase()}`
            : undefined,
        },
      ],
    };
  });

  const currentImage = fullSizeImgs[currentImageIndex];

  const trackDownload = () => {
    trackEvent({
      category: `Album: ${folder}`,
      action: `download image: ${currentImage.name}`,
    });
  };

  return (
    <Container>
      <PhotoAlbum
        layout="masonry"
        photos={thumbSizeImgs}
        onClick={openLightbox}
        spacing={isMobile ? 10 : 40}
        breakpoints={[450, 700, 1200]}
        renderPhoto={(renderProps) => <Picture renderProps={renderProps} />}
      />
      <Lightbox
        open={viewerIsOpen}
        close={closeLightbox}
        slides={fullSizeImgs as Slide[]}
        carousel={{ preload: 2, padding: 0, finite: true }}
        index={currentImageIndex}
        on={{ view: (index) => setCurrentImageIndex(index) }}
        plugins={[Captions, Video]}
        captions={{ descriptionTextAlign: 'center' }}
        video={{ autoPlay: true, playsInline: true, controls: false }}
        toolbar={{
          buttons: [
            <Download
              key={0}
              fileSrc={currentImage.src}
              trackAction={trackDownload}
            />,
            'close',
          ],
        }}
        // thumbnails={{
        //   imageFit: 'cover',
        //   borderRadius: 5,
        // }}
        render={{
          iconLoading: () => (
            <RotatingSquare
              height="150"
              width="150"
              color="#ebebeb"
              ariaLabel="loading"
            />
          ),
        }}
        styles={{
          button: {
            color: '#1a1a1a',
            filter: 'drop-shadow(2px 2px 2px rgba(26, 26, 26, 0.3))',
          },
          container: {
            background: 'white',
            color: 'black',
            paddingTop: 60,
          },
          captionsDescription: { color: '#1a1a1a' },
          captionsDescriptionContainer: {
            padding: 0,
            background: '#ffffff21',
          },
        }}
      />
      <ButtonWrapper>
        <ScrollTopButton onClick={scrollToStart}>Zum Anfang</ScrollTopButton>
      </ButtonWrapper>
    </Container>
  );
};

export default Gallery;
