import { cloneElement } from 'react';
import { useRef } from 'react';
import { TAnimation } from '../ScrollLayer';
import { useInViewport } from 'utils/hooks';
import { ChildContainer } from './styled';

interface IPage {
  children: React.ReactElement<any>;
  id?: string;
  animation?: TAnimation;
}

const Page = ({ children, id, animation = 'wipe' }: IPage) => {
  const childsRef = useRef(null);
  const inViewPort = useInViewport(childsRef, '-100px');

  const Child = () => cloneElement(children, { inView: inViewPort });

  return (
    <section id={id} className={animation}>
      <ChildContainer ref={childsRef}>
        <Child />
      </ChildContainer>
    </section>
  );
};

export default Page;
