import styled from 'styled-components';

interface IContainer {
  active: boolean;
  direction: 'up' | 'down';
}

export const FadeOverlay = styled.div<IContainer>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  width: 100vw;
  height: 100dvh;
  top: 0;
  z-index: 10;
  opacity: 0;
  background: #111;

  animation: ${({ direction }) => direction && 'fadeing'} 2s forwards;

  @keyframes fadeing {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 1;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const WipeOverlay = styled.div<IContainer>`
  display: ${({ active }) => (active ? 'block' : 'none')};
  position: absolute;
  width: 100vw;
  background: white;
  z-index: 10;
  animation: ${({ direction }) =>
      direction === 'up' ? 'paginateBack' : 'paginate'}
    1.5s;

  @keyframes paginate {
    0% {
      height: 0vh;
      top: 100%;
    }
    60% {
      height: 100dvh;
      top: 0;
    }
    100% {
      top: 0;
      height: 0vh;
    }
  }

  @keyframes paginateBack {
    0% {
      height: 0vh;
      top: 0;
    }
    60% {
      height: 100dvh;
      top: 0;
    }
    100% {
      height: 0vh;
      top: 100%;
    }
  }
`;
