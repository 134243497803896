import { Container, IconWrapper } from './styled';
import { BsInstagram } from 'react-icons/bs';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const SocialArea = () => {
  const { trackEvent } = useMatomo();

  const clickAction = () => {
    trackEvent({
      category: 'page',
      action: 'open instagram link',
    });
    window.open(`https://www.instagram.com/crdlux/`, '_blank');
  };

  return (
    <Container>
      <IconWrapper>
        <BsInstagram size={30} onClick={clickAction} />
      </IconWrapper>
    </Container>
  );
};

export default SocialArea;
