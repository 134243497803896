import styled from 'styled-components';

export const ChildContainer = styled.div`
  height: 100dvh;
  overflow: hidden;
`;

export const ChildAnimation = styled.div`
  animation: focus 2.5s ease-in-out;

  @keyframes focus {
    from {
      transform: scale(2);
    }
    to {
      transform: scale(1);
    }
  }
`;
