import { TbCloudDownload } from 'react-icons/tb';
import { saveAs } from 'file-saver';
import { Container } from './styled';

interface IDownload {
  fileSrc: string;
  trackAction?: () => void;
}

const Download = ({ fileSrc, trackAction }: IDownload) => {
  const downloadImg = () => {
    const fileName = fileSrc.split('/').slice(-1).pop();
    saveAs(fileSrc, fileName);
    trackAction && trackAction();
  };

  return (
    <Container onClick={downloadImg}>
      <TbCloudDownload size={30} />
    </Container>
  );
};

export default Download;
