import { useEffect, useState } from 'react';
import * as SC from './styled';
import { DisableScrollbar } from 'utils/globalStyles';

interface IOverlay {
  enabled: boolean;
  children: JSX.Element;
  overlayVisible?: (x: boolean) => void;
}

const Overlay = ({ enabled, children, overlayVisible }: IOverlay) => {
  const [isVisible, setVisible] = useState(enabled);

  useEffect(() => {
    if (enabled) {
      setVisible(true);
    } else {
      setTimeout(() => {
        setVisible(false);
      }, 1000);
    }
  }, [enabled]);

  useEffect(() => {
    overlayVisible && overlayVisible(isVisible);
  }, [isVisible, overlayVisible]);

  return isVisible ? (
    <SC.OverlayWrapper>
      <DisableScrollbar />

      <SC.Overlay enabled={enabled} />
      <SC.Content enabled={enabled}>{children}</SC.Content>
    </SC.OverlayWrapper>
  ) : null;
};

export default Overlay;
