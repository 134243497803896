import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { NavContext } from 'context/NavContext';
import Logo from '../Logo';
import Navigation from '../Navigation';
import * as SC from './styled';
import NavDots from '../NavDots';

interface IHeader {
  isLogoEnabled: boolean;
}
const Header = ({ isLogoEnabled }: IHeader) => {
  const navigate = useNavigate();
  const {
    actions: { setPageIndex },
  } = useContext(NavContext);

  return isLogoEnabled ? (
    <SC.Container>
      <SC.LogoWrapper
        onClick={() => {
          setPageIndex(0);
          navigate('/');
        }}>
        <Logo />
      </SC.LogoWrapper>

      <NavDots />

      <SC.NavWrapper>
        <Navigation />
      </SC.NavWrapper>
    </SC.Container>
  ) : null;
};

export default Header;
