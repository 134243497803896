import styled from 'styled-components';

export const Container = styled.div`
  padding: 8px;
  padding-right: 15px;
  cursor: pointer;
  color: black;

  svg {
    stroke-width: 1px;
    filter: drop-shadow(rgba(26, 26, 26, 0.3) 2px 2px 2px);
  }

  &:hover {
    svg {
      stroke-width: 1.3px;
      filter: drop-shadow(rgba(0, 0, 0, 0.8) 2px 2px 2px);
    }
  }

  &:active {
    svg {
      stroke-width: 1.2px;
      filter: drop-shadow(rgba(0, 0, 0, 0) 2px 2px 2px);
    }
  }
`;
