import { useContext } from 'react';
import { NavContext } from 'context/NavContext';
import { TNavTo } from '../types';
import * as SC from './styled';

interface INavlist {
  navTo: (x: TNavTo) => void;
}
const Navlist = ({ navTo }: INavlist) => {
  const {
    state: { index: currentIndex },
  } = useContext(NavContext);

  return (
    <SC.List index={currentIndex}>
      <ul>
        <li onClick={() => navTo({ index: 0 })}>Slideshow</li>
        <li onClick={() => navTo({ index: 1 })}>Über mich</li>
        <li onClick={() => navTo({ index: 2 })}>Portfolio</li>
        <li onClick={() => navTo({ index: 3 })}>Kontakt</li>
        <SC.GalleryLink onClick={() => navTo({ url: '/galerie', index: 4 })}>
          Kundengalerie
        </SC.GalleryLink>
      </ul>
    </SC.List>
  );
};

export default Navlist;
