import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

export const Landing = styled.div`
  width: 100%;
  height: 100%;
  display: block;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  transform: translate(-50%, -80%);
  width: 95vw;
  max-width: 800px;
  top: 20vh;
  left: 50%;

  @media ${BREAKPOINT.MEDIUM} {
    top: 10vh;
  }
`;

export const Content = styled.div<{ enabled: boolean }>`
  display: ${({ enabled }) => (enabled ? 'block' : 'none')};
`;
