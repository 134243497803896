import styled from 'styled-components';

const color = '#fff';
const animation = '750ms';
const scale = 0.8;

export const Burger = styled.div`
  -webkit-tap-highlight-color: transparent;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  h4 {
    font-family: arial, helvetica, serif;
    color: ${color};
    font-size: 18px;
    text-align: center;
    margin: 40px 0 0;
  }

  .hamburger-menu {
    transform: scale(${scale});
    position: relative;
    display: block;
    width: 64px;
    height: 64px;
    -webkit-touch-callout: none;
    user-select: none;
    cursor: pointer;
    z-index: 5;
    opacity: 0.9;
  }

  #top,
  #bottom {
    position: absolute;
    display: block;
    height: 4px;
    width: 36px;
    border-radius: 2px;
    background: ${color};
  }

  #top {
    top: 19px;
    left: 14px;
  }

  #bottom {
    top: 41px;
    left: 14px;
  }

  path,
  line {
    stroke: ${color};
    stroke-linecap: round;
  }

  #top,
  #bottom {
    transform-origin: 34px 2px;
    transform: rotate(0deg);
    transition: all ${animation} * (2/3) cubic-bezier(0.8, -1.1, 0.5, 1.9);
  }

  #circle {
    stroke-dasharray: 1 100 32 300;
    stroke-dashoffset: 101;
    transition: all ${animation} ease;
  }

  .is-open {
    border-radius: 32px;

    #circle {
      stroke-dasharray: 1 100 190 300;
      stroke-dashoffset: 1;
    }

    #top {
      transform: translateX(-4.5px) rotate(-45deg);
    }

    #bottom {
      transform: translateX(-4.5px) rotate(45deg);
    }
  }
`;
