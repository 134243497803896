import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100dvh;
  display: block;
  overflow: hidden;
  position: relative;

  .slick-slider {
    height: 100dvh;
  }
`;

export const SlideImage = styled.div`
  height: 100dvh;
  width: 100vw;
`;

interface IImage {
  image: string;
}

export const Image = styled.div<IImage>`
  background-image: url(${(props) => props.image});
  width: 100%;
  height: 100dvh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: brightness(0.9);
  animation: 4s ease-in-out 1.3s opacity;
`;

const Nav = styled.div`
  /* position: absolute; */
  /* padding: 15px 50px; */
  /* bottom: 0; */
  z-index: 1;
  color: white;
  letter-spacing: 0.2rem;
  text-align: center;
  font-family: 'Crimson Text';
  font-size: 1.2rem;
  font-weight: 100;

  > div {
    padding: 6px 0;
  }
`;

export const RightNav = styled(Nav)`
  right: 0;
  cursor: pointer;
`;

export const LeftNav = styled(Nav)`
  left: 0;
  cursor: pointer;
`;

export const Indicator = styled(Nav)`
  width: 100px;
  padding: 23px 0;
  bottom: 1px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;
