import { useContext, useState } from 'react';
import BurgerIcon from './BurgerIcon';
import Overlay from '../Overlay';
import Navlist from './Navlist';
import { NavContext } from 'context/NavContext';
import SocialArea from './Social';
import { useNavigate } from 'react-router-dom';
import { NavContainer } from './styled';
import { TNavTo } from './types';

const Navigation = () => {
  const [navIsOpen, setNavIsOpen] = useState(false);
  const [overlayOpen, SetOverlayOpen] = useState(false);
  const navigate = useNavigate();

  const {
    actions: { setPageIndex },
  } = useContext(NavContext);

  const navTo = ({ index, url }: TNavTo) => {
    if (!url) {
      navigate('/');
      setPageIndex(index);
    } else {
      navigate(url);
      setPageIndex(index);
    }

    setNavIsOpen(false);
  };

  const handleNavState = (state: boolean) => {
    if ((overlayOpen && !state) || (!overlayOpen && state)) {
      setNavIsOpen(state);
    }
  };

  return (
    <>
      <Overlay enabled={navIsOpen} overlayVisible={SetOverlayOpen}>
        <NavContainer>
          <Navlist navTo={navTo} />
          <SocialArea />
        </NavContainer>
      </Overlay>

      <BurgerIcon setIconState={handleNavState} iconState={navIsOpen} />
    </>
  );
};

export default Navigation;
