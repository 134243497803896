/* eslint-disable no-alert */
import { useEffect } from 'react';

const usePageLeavingGuard = () => {
  useEffect(() => {
    location.hash = '#album';

    window.onpopstate = function () {
      if (location.pathname === '/galerie' && location.hash !== '#album') {
        if (window.confirm('Möchtest du das Album verlassen?')) {
          location.href = '/';
        } else {
          window.history.go(1);
        }
      }
    };

    return () => {
      window.onpopstate;
    };
  }, []);

  return true;
};

export default usePageLeavingGuard;
