import { RefObject, useEffect, useState } from 'react';

const useInViewport = (
  element: RefObject<any>,
  rootMargin: string | undefined
) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const currentElement = element?.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        setState(entry.isIntersecting);
      },
      { rootMargin }
    );

    currentElement && observer.observe(currentElement);

    return () => currentElement && observer.unobserve(currentElement);
  }, [element, rootMargin]);

  return isVisible;
};

export default useInViewport;
