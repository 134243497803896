import Modal from 'react-modal';
import Form from '../ContactForm';
import * as SC from './styled';

interface IContactModal {
  formIsActive: (x: boolean) => void;
  formIsSent?: () => void;
  enabled: boolean;
}

const ContactModal = ({ enabled, formIsActive, formIsSent }: IContactModal) => {
  const closeModal = () => formIsActive(false);

  return (
    <Modal
      isOpen={enabled}
      ariaHideApp={false}
      preventScroll={true}
      style={{
        overlay: {
          zIndex: 20,
        },
        content: {
          padding: 0,
          left: 0,
          top: 0,
          border: 'none',
          borderRadius: 0,
          width: '100vw',
          height: '100dvh',
          overflow: 'hidden',
          background: '#141414d1',
        },
      }}>
      <SC.ModalWrapper>
        <SC.CloseButton onClick={closeModal}>+</SC.CloseButton>
        <SC.FormWrapper>
          <Form
            formIsActive={formIsActive}
            formIsSent={formIsSent}
            isDisabled={false}
          />
        </SC.FormWrapper>
      </SC.ModalWrapper>
    </Modal>
  );
};

export default ContactModal;
