import { useContext } from 'react';
import { Container, Dot } from './styled';
import { NavContext } from 'context/NavContext';

const NavDots = () => {
  const {
    state: { index: currentIndex },
  } = useContext(NavContext);

  const indexArr = ['', '', '', ''];

  return (
    (currentIndex < indexArr.length && (
      <Container>
        {indexArr.map((val, index) => (
          <Dot key={index} marked={index === currentIndex} />
        ))}
      </Container>
    )) || <></>
  );
};

export default NavDots;
