import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

interface IList {
  index: number;
}
export const List = styled.div<IList>`
  margin: auto;
  font-size: 2.5rem;
  line-height: 1.5em;
  color: #ffffffbf;

  @media ${BREAKPOINT.MEDIUM} {
    font-size: 3rem;
  }

  li {
    transition: 0.3s;
    text-align: center;
    margin: auto;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      color: black;
    }

    :nth-child(${({ index }) => index + 1}) {
      border-left: 8px solid;
      border-right: 8px solid;
      width: 360px;

      color: white;
      animation: resize 0.8s ease forwards;

      @media ${BREAKPOINT.MEDIUM} {
        animation: resizeMedium 0.8s ease forwards;
      }

      &:hover {
        color: black;
      }

      @keyframes resize {
        to {
          width: 280px;
        }
      }

      @keyframes resizeMedium {
        to {
          width: 330px;
        }
      }
    }
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
  }
`;

export const GalleryLink = styled.li`
  color: white;
  padding: 20px 0;
  background-image: url(./imgs/gallery_link_background.png);
  background-repeat: no-repeat;
  background-position: center;
`;
