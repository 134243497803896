import { createGlobalStyle } from 'styled-components';

export const DisableScrollbar = createGlobalStyle`
  body {
    overflow: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
