import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

export const Container = styled.div`
  position: fixed;
  height: 100dvh;
  width: 100vw;
  background: url('./imgs/login_background.png');
  background-size: cover;
`;

export const Inner = styled.div`
  position: absolute;
  padding: 10px;
  width: 80%;
  max-width: 400px;
  margin: 0;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #9f9f9ff2;
  padding: 25px 15px;
  border-radius: 15px;

  /* border: 10px double #8f8f8f;
  box-shadow: #222222 0px 0px 35px 20px; */

  /* background: #181818e0;
  border: 2px solid #9b9b9be6;
  box-shadow: rgb(0 0 0) 0px 0px 35px 20px; */

  background: rgb(108 108 108 / 88%);
  border: 2px solid rgba(155, 155, 155, 0.9);
  box-shadow: rgb(0, 0, 0) 0px 0px 15px 0px;

  @media ${BREAKPOINT.MEDIUM} {
    padding: 40px;
  }
`;

export const Form = styled.form`
  height: 100%;

  button {
    width: 100%;
  }

  /* input {
    &:not(:focus) {
      background-color: #878787;
    }
  } */
`;

export const LoadingAnimation = styled.div`
  align-content: center;
  width: 150px;
  height: 150px;
  margin: auto;
`;

export const ErrorMessage = styled.p`
  color: #ffafaf;
  text-align: center;
  font-weight: 600;
  padding: 0 5px;
  margin-top: 0;
  margin-bottom: 45px;
  cursor: pointer;

  u {
    line-height: 2.5rem;
    font-weight: bolder;
  }

  @media ${BREAKPOINT.MEDIUM} {
    margin-top: -45px;
  }
`;
