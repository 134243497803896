import { useEffect, useState } from 'react';
import { breakpoints } from '../constants';

const useBreakpoint = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  const isMobile = width < breakpoints.medium;

  return { width, isMobile };
};

export default useBreakpoint;
