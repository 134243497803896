import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useBreakpoint } from 'utils/hooks';
import * as SC from './styled';
import { handleFormSubmit } from './submit';

interface IForm {
  formIsActive: (x: boolean) => void;
  formIsSent?: () => void;
  isDisabled: boolean;
}

const Form = ({ formIsActive, formIsSent, isDisabled }: IForm) => {
  const { isMobile } = useBreakpoint();
  const { trackEvent } = useMatomo();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isEnabled, setIsEnabled] = useState(true);

  const trackSend = () => {
    trackEvent({ category: `Contact`, action: `sent contact` });
  };

  const onSubmit = (data: FieldValues) => {
    setTimeout(() => {
      setIsEnabled(false);
      formIsActive(false);
    }, 500);

    handleFormSubmit(data)
      .then(() => console.log('FORM SENT'))
      .catch((e) => console.log('FORM ERROR: ', e))
      .finally(() => formIsSent && formIsSent());

    trackSend();
  };

  return (
    <SC.FormContainer>
      <SC.Form
        onClick={() => formIsActive(true)}
        onSubmit={handleSubmit(onSubmit)}
        enabled={isEnabled}>
        <SC.InputField
          placeholder="Name"
          type="text"
          {...register('name', { required: true })}
          hasError={!!errors.name}
        />

        <SC.InputField
          placeholder="E-Mail"
          inputMode="email"
          type="email"
          {...register('email', { required: true })}
          hasError={!!errors.email}
        />

        <SC.InputArea
          placeholder="Deine Nachricht"
          inputMode="text"
          rows={isMobile ? 3 : 5}
          {...register('message', { required: true })}
          hasError={!!errors.message}
        />

        <SC.SubmitButton disabled={isDisabled} type="submit">
          <span>Senden</span>
        </SC.SubmitButton>
      </SC.Form>
    </SC.FormContainer>
  );
};

export default Form;
