import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect } from 'react';
import Typist from 'react-typist';

import * as SC from './styled';

interface IInfo {
  inView?: boolean;
}

const Info = ({ inView }: IInfo) => {
  const { trackPageView } = useMatomo();

  useEffect(() => {
    inView &&
      trackPageView({
        documentTitle: '#Info',
      });
  }, [inView, trackPageView]);

  return (
    <SC.Info>
      <SC.Text>
        <Typist cursor={{ show: false }}>
          <p>Hey, mein Name ist Christopher.</p>
          <p>
            Ich bin Fotograf und Software Entwickler aus dem Raum Frankfurt am
            Main.
          </p>
        </Typist>
      </SC.Text>
      <SC.Image src="/imgs/me.webp" />
    </SC.Info>
  );
};

export default Info;
