import { useContext, useEffect, useState } from 'react';
import { useImagePreloader } from 'utils/hooks';
import { NavContext } from 'context/NavContext';
import api from 'utils/api';
import CAAlbum from './album';
import CALogin from './login';
import { AlbumType } from './types';

const CustomerArea = () => {
  const {
    actions: { setPageIndex },
  } = useContext(NavContext);

  const [albumData, setAlbumData] = useState<AlbumType>();

  const titleImage =
    (albumData && [
      api.defaults.baseURL +
        '/galleries/' +
        albumData?.folder +
        '/' +
        albumData?.titleImg,
    ]) ||
    [];

  const { imagesPreloaded } = useImagePreloader(titleImage);

  const isLoading = !!albumData?.images.length && !imagesPreloaded;

  useEffect(() => {
    setPageIndex(4);
  }, [setPageIndex]);

  return (
    <>
      {albumData?.images.length && imagesPreloaded ? (
        <CAAlbum albumData={albumData} />
      ) : (
        <CALogin setAlbumData={setAlbumData} isLoading={isLoading} />
      )}
    </>
  );
};

export default CustomerArea;
