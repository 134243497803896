import { useEffect, useRef } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import Gallery from 'components/Gallery';
import Download from 'components/Gallery/ActionIcons/Download';
import Signature from 'components/Signature';
import HoverButton from 'components/Hoverbutton';
import api from 'utils/api';
import { useInViewport } from 'utils/hooks';
import usePageLeavingGuard from 'utils/hooks/usePageLeavingGuard';
import { AlbumType } from '../types';
import {
  Container,
  Header,
  HeaderContent,
  SignatureWrapper,
  OptionsArea,
  ShowButton,
  Title,
  TitleField,
  Tools,
} from './styled';

interface ICAAlbum {
  albumData: AlbumType;
}
const CAAlbum = ({ albumData }: ICAAlbum) => {
  const optionAreaRef = useRef<null | HTMLDivElement>(null);
  const { trackPageView, trackEvent } = useMatomo();
  usePageLeavingGuard();

  const {
    title,
    subtitle,
    titleImg,
    fullDownload,
    folder,
    hint: hasHint,
  } = albumData;

  const srcFolder = api.defaults.baseURL + '/galleries/' + folder;
  const titleImage = srcFolder + '/' + titleImg;
  const downloadPath = srcFolder + '/' + fullDownload;

  const trackDownload = () => {
    trackEvent({ category: `Album: ${folder}`, action: `download album` });
  };

  const trackContact = () => {
    trackEvent({ category: `Album: ${folder}`, action: `call contact form` });
  };

  const scrollToStart = () =>
    optionAreaRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

  const optionsInView = useInViewport(optionAreaRef, '-100px');

  useEffect(() => {
    trackPageView({
      documentTitle: `Album: ${folder}`,
    });
  }, [folder, trackPageView]);

  return (
    <Container>
      <Header titleImage={titleImage}>
        <HeaderContent>
          <Title>
            <h1>{title}</h1>
            <h3>{subtitle}</h3>
          </Title>

          <ShowButton onClick={scrollToStart}>Galerie ansehen</ShowButton>
        </HeaderContent>
      </Header>

      <OptionsArea ref={optionAreaRef}>
        <TitleField>
          <SignatureWrapper>
            <Signature />
          </SignatureWrapper>
        </TitleField>
        <Tools>
          {fullDownload && (
            <Download
              fileSrc={downloadPath || ''}
              trackAction={trackDownload}
            />
          )}
        </Tools>
      </OptionsArea>

      <Gallery scrollToStart={scrollToStart} albumData={albumData} />

      <HoverButton
        isVisible={optionsInView}
        hint={hasHint}
        trackAction={trackContact}
      />
    </Container>
  );
};

export default CAAlbum;
