import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Form from 'components/ContactForm';
import { useBreakpoint } from 'utils/hooks';
import * as SC from './styled';

interface IContact {
  inView?: boolean;
}
const Contact = ({ inView }: IContact) => {
  const [isActive, setIsActive] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const { isMobile } = useBreakpoint();
  const { trackPageView } = useMatomo();

  const sendForm = () => setIsSent(true);

  useEffect(() => {
    inView &&
      trackPageView({
        documentTitle: '#Contact',
        category: `Contact`,
      });
  }, [inView, trackPageView]);

  return (
    <>
      <SC.Container>
        <TitleFootage />

        <SC.TextContainer>
          <SC.FormWrapper>
            {isSent ? (
              <SC.SuccessImg src="/check.png" />
            ) : (
              <Form
                isDisabled={isMobile}
                formIsActive={setIsActive}
                formIsSent={sendForm}
              />
            )}
          </SC.FormWrapper>
        </SC.TextContainer>

        <SC.Headline>
          Lass' uns in <br /> Kontakt treten
        </SC.Headline>
      </SC.Container>

      {isActive && isMobile && (
        <FormModal formIsActive={setIsActive} formIsSent={sendForm} />
      )}
    </>
  );
};

const TitleFootage = () => (
  <SC.ImageContainer>
    <video preload="yes" loop autoPlay muted playsInline>
      <source src="/vids/example.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  </SC.ImageContainer>
);

const FormModal = ({
  formIsActive,
  formIsSent,
}: {
  formIsActive: (x: boolean) => void;
  formIsSent: () => void;
}) => {
  useEffect(() => {
    const handleWindowResize = () => {
      document.getElementById('contact')?.scrollIntoView({ block: 'start' });
    };

    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <Modal
      isOpen={true}
      ariaHideApp={false}
      style={{
        content: {
          padding: 0,
          left: 0,
          top: 0,
          border: 'none',
          borderRadius: 0,
          width: '100vw',
          height: '100dvh',
        },
      }}>
      <SC.ModalWrapper>
        <SC.FormWrapper>
          <Form
            formIsActive={formIsActive}
            formIsSent={formIsSent}
            isDisabled={false}
          />
        </SC.FormWrapper>
      </SC.ModalWrapper>
    </Modal>
  );
};

export default Contact;
