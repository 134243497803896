import styled from 'styled-components';

type Container = {
  isVisible: boolean;
};
export const Container = styled.div<Container>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  filter: drop-shadow(2px 4px 6px black);

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 2s ease;
`;

export const Icon = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100px;

  background-image: url('/imgs/me.webp');
  background-size: cover;
  cursor: pointer;

  transition: scale 0.2s ease;

  &:hover {
    scale: 1.15;
  }
`;

export const Hint = styled.div`
  cursor: pointer;
`;

export const CloseHintButton = styled.div`
  position: absolute;
  top: 0px;
  right: 2px;
  height: 10px;
  width: 10px;
  font-size: 20px;
  color: darkgrey;
  transform: rotate(45deg);
`;

export const HintMessage = styled.div``;
