export const breakpoints = {
  medium: 770,
};

export const BREAKPOINT = {
  SMALL: `(max-width: ${breakpoints.medium}px)`,
  MEDIUM: `(min-width: ${breakpoints.medium}px)`,
};

export const MAILER_PATH = '/contact';

export const CUSTOMER_AREA_LOGIN = '/login';
