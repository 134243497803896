import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

export const FormWrapper = styled.div`
  width: 85%;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  position: relative;
  padding-top: 80px;
  margin: 10vh auto;
  height: 400px;
  width: 95vw;
  max-width: 800px;
  background: #767676;
  border-radius: 10px;
  filter: drop-shadow(#141414 0px 0px 7px);

  @media ${BREAKPOINT.MEDIUM} {
    width: 80vw;
    max-height: 700px;
    min-height: 600px;
    height: 70vh;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 0px;
  height: 50px;
  width: 50px;
  font-size: 50px;
  color: white;
  transform: rotate(45deg);
  cursor: pointer;
`;
