import axios from 'axios';

const DEV_HOST = 'http://localhost:3000';

const api = axios.create({
  timeout: 3000,
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: '',
});

if (process.env.NODE_ENV === 'development') {
  api.defaults.baseURL = DEV_HOST;
}

export default api;
