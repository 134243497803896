import { useState } from 'react';
import { RenderPhotoProps } from 'react-photo-album';
import { Container, NameTag } from './styled';

//TODO: type correctly
interface IPicture {
  renderProps: RenderPhotoProps<{
    src: string;
    title: string;
    path: string;
    name: string;
    key: string;
    alt?: string | undefined;
    width: number;
    height: number;
    type: string;
  }>;
}

const Picture = ({ renderProps }: IPicture) => {
  const { photo, wrapperStyle, renderDefaultPhoto } = renderProps;
  const [hovered, setHovered] = useState(false);

  return (
    <Container
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={{
        position: 'relative',
        background: 'lightgrey',
        ...wrapperStyle,
      }}>
      {renderDefaultPhoto({ wrapped: true })}
      {photo.title && hovered && <NameTag>{photo.title}</NameTag>}
    </Container>
  );
};

export default Picture;
