import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { InputField, SubmitButton } from 'components/ContactForm/styled';
import { AlbumType } from '../types';
import { handleFormSubmit } from './api';
import LoadingAnimation from './LoadingAnimation';
import * as SC from './styled';
import ContactModal from 'components/ContactModal';
import { clearHashFromRoute } from 'utils/utils';

interface ICALogin {
  setAlbumData: (albumData: AlbumType) => void;
  isLoading: boolean;
}

const CALogin = ({ setAlbumData, isLoading }: ICALogin) => {
  const { trackPageView, trackEvent } = useMatomo();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [fieldError, setfieldError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasError = !!errors.password || fieldError;

  const wrongPasswordAction = (field: FieldValues) => {
    setfieldError(true);
    trackEvent({
      category: 'Galery',
      action: `wrong password: ${field.password}`,
    });
  };

  const onSubmit = (password: FieldValues) => {
    handleFormSubmit(password)
      .then(({ data }) => setAlbumData(data))
      .catch(() => wrongPasswordAction(password))
      .finally(() => console.log('logged in'));
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    trackPageView({
      documentTitle: 'Galery',
    });
  }, [trackPageView]);

  useEffect(() => {
    clearHashFromRoute();
  }, []);

  return (
    <SC.Container>
      <SC.Inner>
        {!isLoading ? (
          <SC.Form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              placeholder="Zugangscode"
              type="text"
              {...register('password', { required: true })}
              hasError={hasError}
            />
            {hasError && (
              <SC.ErrorMessage onClick={openModal}>
                Album (noch) nicht verfügbar oder Zugangscode falsch. <br />
                Bei Fragen schreibe mir gerne eine
                <br />
                <u>Nachricht</u>.
              </SC.ErrorMessage>
            )}
            <SubmitButton disabled={false} type="submit">
              <span>Album öffnen</span>
            </SubmitButton>
          </SC.Form>
        ) : (
          <LoadingAnimation />
        )}
      </SC.Inner>

      <ContactModal enabled={isModalOpen} formIsActive={setIsModalOpen} />
    </SC.Container>
  );
};

export default CALogin;
