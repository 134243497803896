import styled from 'styled-components';

export const ScrollArrow = styled.div`
  position: absolute;
  z-index: 1;
  width: 50px;
  height: 50px;
  color: white;
  opacity: 0;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: 0 auto;
  cursor: pointer;

  animation: 3s ease-in-out 0.3s show infinite;

  @keyframes show {
    0% {
      opacity: 0.4;
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
`;
