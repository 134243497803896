import { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { NavProvider } from './context/NavContext';
import Header from './components/Header';
import Overlay from './components/Overlay';
import * as SC from './views/landing/styled';
import CustomerArea from './views/customerArea';
import Landing from './views/landing/landing';
import Logo from './components/Logo';
import { MatomoProvider } from '@jonkoops/matomo-tracker-react';
import matomoInstance from './matomo.config';
import Footer from 'components/Footer';

const App = () => {
  const [showOverlay, setShowOverlay] = useState<boolean>(true);
  const [showLogo, setShowLogo] = useState<boolean>(false);

  setTimeout(() => {
    setShowLogo(true);
    window.scrollTo(0, 1);
  }, 2500);

  setTimeout(() => {
    setShowOverlay(false);
  }, 1500);

  return (
    <MatomoProvider value={matomoInstance}>
      <Router>
        <NavProvider>
          <Header isLogoEnabled={showLogo} />
          <Footer />
          <Overlay enabled={showOverlay}>
            <SC.LogoWrapper>
              <Logo />
            </SC.LogoWrapper>
          </Overlay>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/galerie" element={<CustomerArea />} />
            <Route path="*" element={<Landing />} />
          </Routes>
        </NavProvider>
      </Router>
    </MatomoProvider>
  );
};

export default App;
