import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { CloseHintButton, Container, Hint, HintMessage, Icon } from './styled';
import ContactModal from '../ContactModal';
import { DefaultHintMessage, WeddingHintMessage } from './messages';

interface IHoverButton {
  isVisible: boolean;
  hint?: string;
  trackAction: () => void;
}

const HoverButton = ({ isVisible, hint, trackAction }: IHoverButton) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showElement, setShowElement] = useState(false);
  const [isHintOpen, setIsHintOpen] = useState(false);

  const closeHint = () => {
    setIsHintOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsHintOpen(false);
    trackAction();
  };

  useEffect(() => {
    if (isVisible && !showElement) {
      setShowElement(true);
      setIsHintOpen(true);
    }
  }, [isVisible, showElement]);

  return (
    <>
      <Container isVisible={showElement}>
        <Icon id={'contact_icon'} onClick={openModal} />

        <Hint onClick={closeHint}>
          <Tooltip
            anchorSelect="#contact_icon"
            children={
              <div>
                <CloseHintButton>+</CloseHintButton>
                {hint ? (
                  <HintMessage>
                    <WeddingHintMessage />
                  </HintMessage>
                ) : (
                  <HintMessage>
                    <DefaultHintMessage />
                  </HintMessage>
                )}
              </div>
            }
            offset={15}
            // eslint-disable-next-line react-native/no-inline-styles
            style={{
              maxWidth: 300,
              width: '80vw',
              backgroundColor: 'white',
              color: 'black',
            }}
            isOpen={isHintOpen}
            clickable={true}
            closeOnEsc={true}
          />
        </Hint>
      </Container>
      <ContactModal enabled={isModalOpen} formIsActive={setIsModalOpen} />
    </>
  );
};

export default HoverButton;
