import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

const Nav = styled.div`
  position: absolute;
  z-index: 10;
  color: white;
  letter-spacing: 0.2rem;
  text-align: center;
  font-family: 'Crimson Text';
  font-size: 4rem;
  font-weight: 100;
  -webkit-tap-highlight-color: transparent;

  @media ${BREAKPOINT.MEDIUM} {
    top: 25vh;
  }

  > div {
    padding: 6px 0;
  }
`;

export const RightNav = styled(Nav)`
  right: -10px;
  top: 20vh;
  cursor: pointer;
  opacity: 1;
  animation: blink 0.6s linear 2.5s;

  @media ${BREAKPOINT.MEDIUM} {
    right: 0;
    top: 25vh;
  }

  @keyframes blink {
    25% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.1;
      transform: scale(10);
      color: orange;
    }
    75% {
      opacity: 0.5;
    }
  }
`;

export const LeftNav = styled(Nav)`
  left: 0;
  bottom: 14vh;
  cursor: pointer;
`;

interface IContainer {
  bgColor?: string;
}

export const Container = styled.div<IContainer>`
  position: relative;
  height: calc(100dvh - 10dvh);
  padding: 10dvh 0 0 60px;
  overflow: hidden;
  opacity: 0;
  background-color: ${({ bgColor }) => bgColor || 'black'};
  transition: background-color 1.5s ease-in-out;
  animation: fadeInA 6s ease forwards;

  @media ${BREAKPOINT.MEDIUM} {
    height: calc(100dvh - 8dvh);
    padding: 8dvh 20px 0 80px;
  }

  &:before {
    content: 'crdlux';
    position: absolute;
    opacity: 0;
    bottom: 35px;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 30vw;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 1px #303030;

    animation: fadeInA 3.5s ease forwards;
  }

  @keyframes fadeInA {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
