import { ReactElement, useContext, useEffect } from 'react';
import { NavContext, TDirection } from 'context/NavContext';

import useScrollLayer, { TAnimation } from '../ScrollLayer';
import { Container } from './styled';

interface IFullPageSlider {
  children: ReactElement[];
}

type swipePos = number | null;

const FullPageSlider = ({ children }: IFullPageSlider) => {
  let touchStart: swipePos = null;
  let touchEnd: swipePos = null;

  const [{ fireLayer, Layer }] = useScrollLayer();
  const {
    state: { index: currentIndex, direction: currentDirection },
    actions: { setPageIndex, setPageAmount },
  } = useContext(NavContext);

  const setTouchStart = (i: swipePos) => {
    touchStart = i;
  };

  const setTouchEnd = (i: swipePos) => {
    touchEnd = i;
  };

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  const onTouchStart = (e: TouchEvent) => {
    // console.log('touchstart');
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: TouchEvent) =>
    touchStart && setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    // console.log({ touchStart, touchEnd });
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isUpSwipe || isDownSwipe) {
      // console.log('swipe', isUpSwipe ? 'up' : 'down');
      isUpSwipe ? updateIndex('down') : updateIndex('up');
      setTouchStart(null);
      setTouchEnd(null);
    }
  };

  const updateIndex = (direction: 'up' | 'down') => {
    const indexDirection = (index: number) => {
      if (index > 0 && direction === 'up') return 'up';
      if (index < children.length - 1 && direction === 'down') return 'down';
      return false;
    };

    setPageIndex((prevState: number) => {
      if (indexDirection(prevState) === 'up') return prevState - 1;
      if (indexDirection(prevState) === 'down') return prevState + 1;
      return prevState;
    });
  };

  const executePaging = (index: number, direction: TDirection) => {
    const PAGE_DELAY = 900;

    if (index >= 0 && index <= 3) {
      const element = document.getElementById(children[index].props.id);
      const layerAnimation: TAnimation = element?.className.length
        ? (element?.className as TAnimation)
        : 'wipe';

      // console.log({ layerEnabled: layerAnimation });
      // console.log({ index });

      direction && fireLayer(direction, layerAnimation);
      setTimeout(() => {
        element?.scrollIntoView({ block: 'start' });
      }, PAGE_DELAY);
    } else {
      setPageIndex(0);
    }
  };

  const executeScroll = (e: WheelEvent) => {
    if (e.deltaY < 0) {
      updateIndex('up');
    } else {
      updateIndex('down');
    }
  };

  useEffect(() => {
    executePaging(currentIndex, currentDirection);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, currentDirection]);

  useEffect(() => {
    const selector = document.getElementById(
      'fullpage-scroller'
    ) as HTMLDivElement;

    // console.log('### Called');

    selector.addEventListener('wheel', throttle(executeScroll, 2500));
    selector.addEventListener('touchstart', throttle(onTouchStart, 2500));
    selector.addEventListener('touchmove', onTouchMove);
    selector.addEventListener('touchend', onTouchEnd);

    return () => {
      selector.removeEventListener('wheel', throttle(executeScroll, 2500));
      selector.removeEventListener('touchmove', onTouchMove);
      selector.removeEventListener('touchstart', throttle(onTouchStart, 2500));
      selector.removeEventListener('touchend', onTouchEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPageAmount(children.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children.length]);

  return (
    <Container id={'fullpage-scroller'}>
      {Layer}
      {children}
    </Container>
  );
};

const throttle = (callbackFn: (e: any) => void, limit: number) => {
  let wait = false;
  return (e: Event) => {
    if (!wait) {
      callbackFn(e);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
};

export default FullPageSlider;
