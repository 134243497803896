import styled from 'styled-components';
import { BREAKPOINT } from 'utils/constants';

export const Info = styled.div`
  position: relative;
  display: block;
  height: 100dvh;

  @media ${BREAKPOINT.MEDIUM} {
    display: flex;
  }
`;

export const Text = styled.div`
  position: absolute;
  padding: 130px 20px 0;
  height: 100dvh;
  width: auto;
  background: transparent;
  top: 0;
  left: 0;
  color: #f0f0f0;
  font-size: 1.1em;
  font-family: 'Courgette';

  p {
    margin: 0.5rem 0;
  }

  @media ${BREAKPOINT.MEDIUM} {
    font-size: 1.3em;
    width: 30%;
  }
`;

interface IImage {
  src: string;
}

export const Image = styled.div<IImage>`
  width: auto;
  height: 100dvh;

  @media ${BREAKPOINT.MEDIUM} {
    width: 100vw;
    height: auto;
    background-size: auto 100%;
  }

  background-image: url(${(props) => props.src});
  background-color: #1f1f1f;
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: auto 70%;

  animation: focus 0.6s ease;
  @keyframes focus {
    0% {
      transform: scale(1.8);
    }
    100% {
      transform: scale(1);
    }
  }
`;
