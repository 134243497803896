import { useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ExampleElement, { TExampleElement } from '../ExampleElement';
import * as SC from './styled';

interface IExampleSlider {
  elements: TExampleElement[];
  trackAction?: (index: number) => void;
}

const RightNav = ({ onClick }: any) => {
  return (
    <SC.RightNav onClick={onClick}>
      <IoIosArrowForward size={55} />
    </SC.RightNav>
  );
};

const LeftNav = ({ onClick }: any) => {
  return (
    <SC.LeftNav onClick={onClick}>
      <IoIosArrowBack size={55} />
    </SC.LeftNav>
  );
};

const ExampleSlider = ({ elements, trackAction }: IExampleSlider) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const elementsCount = elements.length;
  const isFirstPage = slideIndex === 0;
  const isLastPage = slideIndex + 1 >= elementsCount;

  const goForward = () => {
    if (slideIndex < elementsCount - 1) {
      setSlideIndex(slideIndex + 1);
      trackAction && trackAction(slideIndex + 1);
    }
  };

  const goBackward = () => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
      trackAction && trackAction(slideIndex - 1);
    }
  };

  return (
    <SC.Container bgColor={elements[slideIndex].accent}>
      {!isFirstPage && <LeftNav onClick={goBackward} />}
      {!isLastPage && <RightNav onClick={goForward} />}
      {elements.map((element, index) => (
        <ExampleElement
          key={index}
          {...element}
          visible={index === slideIndex}
        />
      ))}
    </SC.Container>
  );
};

export default ExampleSlider;
