import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 11;
  opacity: 0;
  animation: fill 1s linear forwards;

  @keyframes fill {
    to {
      opacity: 1;
    }
  }
`;

export const LogoWrapper = styled.div`
  width: 100px;
  height: 75px;
  position: relative;
  margin: 10px;
  float: left;
  cursor: pointer;
`;

export const NavWrapper = styled.div`
  height: 75px;
  margin: 10px;
  float: right;
`;
